<template>
    <div class="site-bottom">
        <div class="site-bottom__background">
            <picture>
                <source media="(max-width: 376px)" srcset="/img/bottom-bg-mob-sm.webp, /img/bottom-bg-mob-sm@2x.webp 2x"
                    type="image/webp">
                <source media="(max-width: 376px)" srcset="/img/bottom-bg-mob-sm.png, /img/bottom-bg-mob-sm@2x.png 2x">
                <source media="(max-width: 425px)" srcset="/img/bottom-bg-mob.webp, /img/bottom-bg-mob@2x.webp 2x"
                    type="image/webp">
                <source media="(max-width: 425px)" srcset="/img/bottom-bg-mob.png, /img/bottom-bg-mob@2x.png 2x">
                <source media="(min-width: 425px)" srcset="/img/bottom-bg.webp, /img/bottom-bg@2x.webp 2x"
                    type="image/webp">
                <source media="(min-width: 425px)" srcset="/img/bottom-bg.png, /img/bottom-bg@2x.png 2x"> <img alt=""
                    height="2408" src="/img/bottom-bg.png" width="1920">
            </picture>
        </div>

        <footer class="footer">
            <div class="container">
                <div class="footer__content">
                    <div class="footer__col"><a class="logo footer__logo" href="#"><svg height="25" width="185">
                                <use xlink:href="img/sprite.svg#logo"></use>
                            </svg> </a> <small class="footer__copyright">Copyright<br>
                            © 2022 KikiVerse</small></div>

                    <div class="footer__col"><span class="text footer__title">Project</span>

                        <ul class="footer__links">
                            <!-- <li class="footer__link"><a href="#team">Team</a></li> -->
                            <li class="footer__link"><a href="#mission">Mission</a></li>
                            <li class="footer__link"><a href="#vision">Vision</a></li>
                            <!-- <svg height="27" width="24"><a class="btn timer__link timer__link--pp"
                                    href="https://app.elixirnft.io/collections/CJKrnvgB5PugtXXMooJq8N31VRtzMowusi9n2SQJEJMh">
                                    <use xlink:href="img/sprite.svg#elixir"></use>
                                </a></svg>
                            <li><a class="btn timer__link timer__link--pp"
                                href="https://app.elixirnft.io/collections/CJKrnvgB5PugtXXMooJq8N31VRtzMowusi9n2SQJEJMh">Buy
                                on Elixir </a> <a class="btn timer__link"
                                href="https://magiceden.io/marketplace/kikiverse"> <svg height="26" width="44">
                                    <use xlink:href="img/sprite.svg#me"></use>
                                </svg> Buy on ME </a> <a class="btn timer__link"
                                href="https://hadeswap.com/collection/HtTcYxavdSCaX33DJKwvjXn7XMcXHWMJVz93vqvD9eLi/buy">
                                <img alt="" height="28" src="/img/hadeswap.svg" width="24"> Buy on Hadeswap </a></li> -->
                    </ul>
                </div>

                <div class="footer__col"><span class="text footer__title">Socials</span>

                    <ul class="footer__links">
                        <li class="footer__link footer__link--iconed"><a href="https://twitter.com/KikiVerseNFT"><svg
                                    fill="#1D9BF0" height="21" width="25">
                                    <use xlink:href="img/sprite.svg#twitter"></use>
                                </svg> <span>Twitter</span> </a></li>
                        <li class="footer__link footer__link--iconed"><a href="https://discord.gg/nF6Z72xN47"><svg
                                    fill="#4A61FC" height="21" width="25">
                                    <use xlink:href="img/sprite.svg#discord"></use>
                                </svg> <span>Discord</span> </a></li>
                    </ul>
                </div>
            </div>

            <div class="footer__side-image">
                <picture>
                    <source srcset="/img/raper.webp, /img/raper@2x.webp 2x" type="image/webp">
                    <source srcset="/img/raper.png, /img/raper@2x.png 2x"> <img alt="" height="670"
                        src="/img/raper.png" width="335">
                </picture>
            </div>
        </div>
    </footer>
</div></template>