<template>
  <div class="not-found-container">
    <div class="not-found-content">
      <h1 class="not-found-title">Oops! Page Not Found</h1>
      <p class="not-found-text">Oh no, you've entered a magical land where pages don't exist!</p>
      <button class="back-button" @click="navigateToHome">Back to Home</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
  methods: {
    navigateToHome() {
      this.$router.push('/');
    },
  },
};
</script>

<style scoped>
.not-found-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #e3d9ff; /* Light purple background */
}

.not-found-content {
  text-align: center;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.not-found-title {
  font-size: 36px;
  color: #8642f4; /* Fun, light purple color */
}

.not-found-text {
  font-size: 18px;
  color: #5c3a85; /* Dark text color */
  margin-top: 10px;
}

.back-button {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #8642f4; /* Fun button color */
  color: #fff; /* White text color */
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.back-button:hover {
  background-color: #5c3a85; /* Slightly darker color on hover */
}
</style>
