<template>
    <section class="about-us" id="mission">
        <div class="container">
            <div class="about-us__wrapper">
                <div class="about-us__inner">
                    <div class="about-us__img-wrapper">
                        <picture>
                            <source srcset="/img/foxy-lg.webp, /img/foxy-lg@2x.webp 2x" type="image/webp">
                            <source srcset="/img/foxy-lg.png, /img/foxy-lg@2x.png 2x"> <img alt="" height="710"
                                src="/img/foxy-lg.png" width="453">
                        </picture>
                    </div>

                    <!-- <div class="about-us__text-content">
                        <h2 class="title about-us__title">Think multi-dimensional, only the sky is the limit!</h2>

                        <div class="about-us__divider"></div>

                        <div class="text about-us__text">
                            <p>At KikiVerse we don't want to limit ourselves. We think web3 is all about pushing the
                                bounderies, open-mindness and a multi-chain future.</p>

                            <p>We love all things art and music! That's why we aspire to combine art and music in
                                unprecedented ways, as a rarity trait and beyond.</p>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</section></template>