<template>
    <section class="vision-map" id="vision">
        <div class="container">
            <div class="vision-map__content">
                <h2 class="title vision-map__title">The cutest entertainment<br>
                    brand</h2>

                <div class="vision-map__images">
                    <div class="vision-map__img">
                        <picture>
                            <source srcset="/img/kikiverse_vision-1.webp, /img/kikiverse_vision-1@2x.webp 2x"
                                type="image/webp">
                            <source srcset="/img/kikiverse_vision-1.png, /img/kikiverse_vision-1@2x.png 2x"> <img alt=""
                                src="/img/kikiverse_vision-1.png" width="1258">
                        </picture>
                    </div>

                    <div class="vision-map__img">
                        <picture>
                            <source srcset="/img/kikiverse_vision-2.webp, /img/kikiverse_vision-2@2x.webp 2x"
                                type="image/webp">
                            <source srcset="/img/kikiverse_vision-2.png, /img/kikiverse_vision-2@2x.png 2x"> <img alt=""
                                src="/img/kikiverse_vision-2.png" width="910">
                        </picture> <!-- <picture>
                                    <source srcset='/img/vision-map.webp, /img/vision-map@2x.webp 2x' type="image/webp" media="(min-width: 426px)"/>
                                    <source srcset='/img/vision-map.png, /img/vision-map@2x.png 2x' media="(min-width: 426px)"/>
                                    <source srcset='/img/vision-map-mob.webp, /img/vision-map-mob@2x.webp 2x' type="image/webp" media="(max-width: 425px)"/>
                                    <source srcset='/img/vision-map-mob.png, /img/vision-map-mob@2x.png 2x' media="(max-width: 425px)"/>
                                    <img src='/img/vision-map.png' alt='' width="1490" height="836" />
                                </picture> -->
                    </div>

                    <div class="vision-map__img">
                        <picture>
                            <source srcset="/img/kikiverse_vision-3.webp, /img/kikiverse_vision-3@2x.webp 2x"
                                type="image/webp">
                            <source srcset="/img/kikiverse_vision-3.png, /img/kikiverse_vision-3@2x.png 2x"> <img alt=""
                                src="/img/kikiverse_vision-3.png" width="666">
                    </picture>
                </div>
            </div>
        </div>

        <div class="vision-map__floating-items">
            <div class="vision-map__stars">
                <picture>
                    <source media="(min-width: 576px)" srcset="/img/stars.webp, /img/stars@2x.webp 2x"
                        type="image/webp">
                    <source media="(min-width: 576px)" srcset="/img/stars.png, /img/stars@2x.png 2x">
                    <source media="(max-width: 576px)" srcset="/img/stars-mob.webp, /img/stars-mob@2x.webp 2x"
                        type="image/webp">
                    <source media="(max-width: 576px)" srcset="/img/stars-mob.png, /img/stars-mob@2x.png 2x"> <img
                        alt="" height="650" src="/img/stars.png" width="1920">
                </picture>
            </div>

            <div class="vision-map__moon">
                <picture>
                    <source srcset="/img/vision-moon.webp, /img/vision-moon@2x.webp 2x" type="image/webp">
                    <source srcset="/img/vision-moon.png, /img/vision-moon@2x.png 2x"> <img alt="" height="747"
                        src="/img/vision-moon.png" width="742">
                </picture>
            </div>

            <div class="vision-map__cloud vision-map__cloud--left">
                <picture>
                    <source srcset="/img/vision-cloud-left.webp, /img/vision-cloud-left@2x.webp 2x" type="image/webp">
                    <source srcset="/img/vision-cloud-left.png, /img/vision-cloud-left@2x.png 2x"> <img alt=""
                        height="676" src="/img/vision-cloud-left.png" width="630">
                </picture>
            </div>

            <div class="vision-map__cloud vision-map__cloud--right">
                <picture>
                    <source srcset="/img/vision-cloud-right.webp, /img/vision-cloud-right@2x.webp 2x"
                        type="image/webp">
                    <source srcset="/img/vision-cloud-right.png, /img/vision-cloud-right@2x.png 2x"> <img alt=""
                        height="277" src="/img/vision-cloud-right.png" width="277">
                </picture>
            </div>

            <div class="vision-map__bottom-city">
                <picture>
                    <source srcset="/img/town.webp, /img/town@2x.webp 2x" type="image/webp">
                    <source srcset="/img/town.png, /img/town@2x.png 2x"> <img alt="" height="537" src="/img/town.png"
                        width="1923">
                </picture>
            </div>
        </div>
    </div>
</section></template>