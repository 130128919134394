<template>
    <section class="shaping-future">
        <div class="container">
            <div class="shaping-future__wrapper">
                <div class="shaping-future__characters">
                    <div class="shaping-future__character shaping-future__character--bear">
                        <picture>
                            <source srcset="/img/bear.webp, /img/bear@2x.webp 2x" type="image/webp">
                            <source srcset="/img/bear.png, /img/bear@2x.png 2x"> <img alt="" height="92"
                                src="/img/bear.png" width="92">
                        </picture>
                    </div>

                    <div class="shaping-future__character shaping-future__character--tiger">
                        <picture>
                            <source srcset="/img/tiger.webp, /img/tiger@2x.webp 2x" type="image/webp">
                            <source srcset="/img/tiger.png, /img/tiger@2x.png 2x"> <img alt="" height="92"
                                src="/img/tiger.png" width="92">
                        </picture>
                    </div>

                    <div class="shaping-future__character shaping-future__character--pepev1">
                        <picture>
                            <source srcset="/img/pepe-sm.webp, /img/pepe-sm@2x.webp 2x" type="image/webp">
                            <source srcset="/img/pepe-sm.png, /img/pepe-sm@2x.png 2x"> <img alt="" height="92"
                                src="/img/pepe-sm.png" width="93">
                        </picture>
                    </div>

                    <div class="shaping-future__character shaping-future__character--dino">
                        <picture>
                            <source srcset="/img/dinosaur.webp, /img/dinosaur@2x.webp 2x" type="image/webp">
                            <source srcset="/img/dinosaur.png, /img/dinosaur@2x.png 2x"> <img alt="" height="92"
                                src="/img/dinosaur.png" width="93">
                        </picture>
                    </div>

                    <div class="shaping-future__character shaping-future__character--whale">
                        <picture>
                            <source srcset="/img/whale.webp, /img/whale@2x.webp 2x" type="image/webp">
                            <source srcset="/img/whale.png, /img/whale@2x.png 2x"> <img alt="" height="92"
                                src="/img/whale.png" width="92">
                        </picture>
                    </div>

                    <div class="shaping-future__character shaping-future__character--crow">
                        <picture>
                            <source srcset="/img/crow-sm.webp, /img/crow-sm@2x.webp 2x" type="image/webp">
                            <source srcset="/img/crow-sm.png, /img/crow-sm@2x.png 2x"> <img alt="" height="92"
                                src="/img/crow-sm.png" width="92">
                        </picture>
                    </div>

                    <div class="shaping-future__character shaping-future__character--pepev2">
                        <picture>
                            <source srcset="/img/pepe-sm-v2.webp, /img/pepe-sm-v2@2x.webp 2x" type="image/webp">
                            <source srcset="/img/pepe-sm-v2.png, /img/pepe-sm-v2@2x.png 2x"> <img alt="" height="92"
                                src="/img/pepe-sm-v2.png" width="75">
                        </picture>
                    </div>

                    <div class="shaping-future__character shaping-future__character--caveman">
                        <picture>
                            <source srcset="/img/caveman-sm.webp, /img/caveman-sm@2x.webp 2x" type="image/webp">
                            <source srcset="/img/caveman-sm.png, /img/caveman-sm@2x.png 2x"> <img alt="" height="92"
                                src="/img/caveman-sm.png" width="92">
                        </picture>
                    </div>

                <div class="shaping-future__character shaping-future__character--duck">
                    <picture>
                        <source srcset="/img/duck-sm.webp, /img/duck-sm@2x.webp 2x" type="image/webp">
                        <source srcset="/img/duck-sm.png, /img/duck-sm@2x.png 2x"> <img alt="" height="92"
                            src="/img/duck-sm.png" width="92">
                    </picture>
                </div>

                <div class="shaping-future__character shaping-future__character--viking">
                    <picture>
                        <source srcset="/img/viking.webp, /img/viking@2x.webp 2x" type="image/webp">
                        <source srcset="/img/viking.png, /img/viking@2x.png 2x"> <img alt="" height="92"
                            src="/img/viking.png" width="88">
                    </picture>
                </div>

                <div class="shaping-future__character shaping-future__character--foxy">
                    <picture>
                        <source srcset="/img/foxy-sm.webp, /img/foxy-sm@2x.webp 2x" type="image/webp">
                        <source srcset="/img/foxy-sm.png, /img/foxy-sm@2x.png 2x"> <img alt="" height="102"
                            src="/img/foxy-sm.png" width="87">
                    </picture>
                </div>

                <div class="shaping-future__character shaping-future__character--spy">
                    <picture>
                        <source srcset="/img/spy-sm.webp, /img/spy-sm@2x.webp 2x" type="image/webp">
                        <source srcset="/img/spy-sm.png, /img/spy-sm@2x.png 2x"> <img alt="" height="96"
                            src="/img/spy-sm.png" width="71">
                    </picture>
                </div>

                <div class="shaping-future__character shaping-future__character--transformer">
                    <picture>
                        <source srcset="/img/tranformer-sm.webp, /img/tranformer-sm@2x.webp 2x" type="image/webp">
                        <source srcset="/img/tranformer-sm.png, /img/tranformer-sm@2x.png 2x"> <img alt="" height="92"
                            src="/img/tranformer-sm.png" width="82">
                    </picture>
                </div>

                <div class="shaping-future__character shaping-future__character--penguin">
                    <picture>
                        <source srcset="/img/penguin.webp, /img/penguin@2x.webp 2x" type="image/webp">
                        <source srcset="/img/penguin.png, /img/penguin@2x.png 2x"> <img alt="" height="92"
                            src="/img/penguin.png" width="82">
                    </picture>
                </div>

                <div class="shaping-future__character shaping-future__character--racoon">
                    <picture>
                        <source srcset="/img/racoon-sm.webp, /img/racoon-sm@2x.webp 2x" type="image/webp">
                        <source srcset="/img/racoon-sm.png, /img/racoon-sm@2x.png 2x"> <img alt="" height="86"
                            src="/img/racoon-sm.png" width="67">
                    </picture>
                </div>
            </div>

            <div class="shaping-future__inner">
                <div class="shaping-future__section shaping-future__section--first">
                    <!-- <div class="shaping-future__content">
                        <h2 class="title shaping-future__main-title">shaping the future together</h2>

                        <div class="shaping-future__divider"></div>

                        <p class="text shaping-future__text">At KikiVerse, our greatest asset is the wisdom of the
                            community. Therefore, it is of great importance for us to listen to you and adjust the
                            course together, without losing sight of the big vision.</p>
                    </div> -->

                    <div class="shaping-future__preview">
                        <div class="shaping-future__img-wrapper">
                            <picture>
                                <source media="(min-width: 576px)"
                                    srcset="/img/gaming-sun.webp, /img/gaming-sun@2x.webp 2x" type="image/webp">
                                <source media="(min-width: 576px)"
                                    srcset="/img/gaming-sun.jpg, /img/gaming-sun@2x.jpg 2x">
                                <source media="(max-width: 576px)"
                                    srcset="/img/gaming-sun-mob.webp, /img/gaming-sun-mob@2x.webp 2x"
                                    type="image/webp">
                                <source media="(max-width: 576px)"
                                    srcset="/img/gaming-sun-mob.jpg, /img/gaming-sun-mob@2x.jpg 2x"> <img alt=""
                                    height="496" src="/img/gaming-sun.jpg" width="787">
                            </picture>
                        </div>
                    </div>
                </div>

                <div class="shaping-future__section shaping-future__section--second">
                    <!-- <div class="shaping-future__content">
                        <h3 class="title title--tertiary shaping-future__title">For this reason, we have consciously
                            decided against a static roadmap.</h3>

                        <p class="text shaping-future__text">Instead we are opting for a dynamic visionmap, based on our
                            concept for KikiVerse as a decentralized entertainment brand, while being open for input and
                            ideas by the community.</p>
                    </div> -->

                    <div class="shaping-future__preview">
                        <div class="shaping-future__img-wrapper">
                            <picture>
                                <source media="(min-width: 576px)" srcset="/img/band.webp, /img/band@2x.webp 2x"
                                    type="image/webp">
                                <source media="(min-width: 576px)" srcset="/img/band.png, /img/band@2x.png 2x">
                                <source media="(max-width: 576px)"
                                    srcset="/img/band-mob.webp, /img/band-mob@2x.webp 2x" type="image/webp">
                                <source media="(max-width: 576px)"
                                    srcset="/img/band-mob.jpg, /img/band-mob@2x.jpg 2x"> <img alt="" height="559"
                                    src="/img/band.jpg" width="797">
                            </picture>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section></template>