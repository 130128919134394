<template>
    <div class="kiki-posters">
        <div class="container">
            <div class="kiki-posters__item">
                <picture>
                    <source srcset="/img/kikiposter-1.webp, /img/kikiposter-1@2x.webp 2x" type="image/webp">
                    <source srcset="/img/kikiposter-1.png, /img/kikiposter-1@2x.png 2x"> <img alt=""
                        src="/img/kikiposter-1.png" width="944">
                </picture>
            </div>

        <div class="kiki-posters__item">
            <picture>
                <source srcset="/img/kikiposter-2.webp, /img/kikiposter-2@2x.webp 2x" type="image/webp">
                <source srcset="/img/kikiposter-2.png, /img/kikiposter-2@2x.png 2x"> <img alt=""
                    src="/img/kikiposter-2.png" width="944">
            </picture>
        </div>
    </div>
</div></template>