<template>
    <section class="hero">
        <div class="hero__bg">
            <picture>
                <source media="(min-width: 425px)" srcset="/img/hero_bg.webp, /img/hero_bg@2x.webp 2x" type="image/webp">
                <source media="(min-width: 425px)" srcset="/img/hero_bg.jpg, /img/hero_bg@2x.jpg 2x">
                <source media="(max-width: 425px)" srcset="/img/hero-bg-mob.webp, /img/hero-bg-mob@2x.webp 2x"
                    type="image/webp">
                <source media="(max-width: 425px)" srcset="/img/hero-bg-mob.jpg, /img/hero-bg-mob@2x.jpg 2x"> <img alt=""
                    height="2560" src="/img/hero_bg.jpg" width="1900">
            </picture>
        </div>

        <div class="container">
            <div class="hero__content">
                <div class="timer hero__timer">
                    <div class="timer__global-wrapper">
                        <h1 class="title timer__title">Be cute, be yourself, be Kiki!</h1>

                        <!-- <div class="timer__links"><a class="btn timer__link timer__link--pp"
                                href="https://app.elixirnft.io/collections/CJKrnvgB5PugtXXMooJq8N31VRtzMowusi9n2SQJEJMh"><svg
                                    height="27" width="24">
                                    <use xlink:href="img/sprite.svg#elixir"></use>
                                </svg> Buy on Elixir </a> <a class="btn timer__link"
                                href="https://magiceden.io/marketplace/kikiverse"> <svg height="26" width="44">
                                    <use xlink:href="img/sprite.svg#me"></use>
                                </svg> Buy on ME </a> <a class="btn timer__link"
                                href="https://hadeswap.com/collection/HtTcYxavdSCaX33DJKwvjXn7XMcXHWMJVz93vqvD9eLi"> <img
                                    alt="" height="28" src="/img/hadeswap.svg" width="24"> Buy on Hadeswap </a>
                        </div> -->
                    </div>

                    <div class="timer__moon">
                        <picture>
                            <source srcset="/img/moon-lg.webp, /img/moon-lg@2x.webp 2x" type="image/webp">
                            <source srcset="/img/moon-lg.png, /img/moon-lg@2x.png 2x"> <img alt="" height="1313"
                                src="/img/moon-lg.png" width="1370">
                        </picture>
                    </div>
                </div>

                <div class="info hero__info">
                    <div class="info__text-content">
                        <h2 class="title title--secondary info__title">Welcome to the <b>KikiVerse</b></h2>
                        <div class="video-container">
                            <iframe src="https://www.youtube.com/embed/yZAIOWDTWEc?si=UQZ20IEnUJXfLvqe" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </div>
                    <!-- <div class="team info__team-wrapper" id="team">
                        <h3 class="title team__title">Team</h3>

                        <div class="team__wrapper">
                            <div class="team__slider-wrapper">
                                <ul class="team__list">
                                    <li class="team__item"><a class="team__img-wrapper"
                                            href="https://twitter.com/bas1call?s=11&amp;t=DyXUEdvGjgDDqhByoN6SqQ">
                                            <picture>
                                                <source srcset="/img/basical.webp, /img/basical@2x.webp 2x"
                                                    type="image/webp">
                                                <source srcset="/img/basical.jpg, /img/basical@2x.jpg 2x"> <img alt=""
                                                    height="202" src="/img/basical.jpg" width="202">
                                            </picture>
                                        </a> <span class="team__name">bas1cal</span></li>
                                    <li class="team__item"><a class="team__img-wrapper"
                                            href="https://twitter.com/cyborg_654?s=11&amp;t=DyXUEdvGjgDDqhByoN6SqQ">
                                            <picture>
                                                <source srcset="/img/clover.webp, /img/clover@2x.webp 2x" type="image/webp">
                                                <source srcset="/img/clover.jpg, /img/clover@2x.jpg 2x"> <img alt=""
                                                    height="202" src="/img/clover.jpg" width="202">
                                            </picture>
                                        </a> <span class="team__name">Cyborg</span></li>
                                    <li class="team__item"><a class="team__img-wrapper"
                                            href="https://twitter.com/pepeclovernft?s=11&amp;t=DyXUEdvGjgDDqhByoN6SqQ">
                                            <picture>
                                                <source srcset="/img/cyborg.webp, /img/cyborg@2x.webp 2x" type="image/webp">
                                                <source srcset="/img/cyborg.jpg, /img/cyborg@2x.jpg 2x"> <img alt=""
                                                    height="202" src="/img/cyborg.jpg" width="202">
                                            </picture>
                                        </a> <span class="team__name">Clover</span></li>
                                </ul>
                            </div>
                        </div>
                    </div> -->

                    <div class="info__characters">
                        <div class="info__character info__character--caveman">
                            <picture>
                                <source srcset="/img/caveman.webp, /img/caveman@2x.webp 2x" type="image/webp">
                                <source srcset="/img/caveman.png, /img/caveman@2x.png 2x"> <img alt="" height="292"
                                    src="/img/caveman.png" width="292">
                            </picture>
                        </div>

                        <div class="info__character info__character--crow">
                            <picture>
                                <source srcset="/img/crow.webp, /img/crow@2x.webp 2x" type="image/webp">
                                <source srcset="/img/crow.png, /img/crow@2x.png 2x"> <img alt="" height="372"
                                    src="/img/crow.png" width="372">
                            </picture>
                        </div>

                        <div class="info__character info__character--pepe">
                            <picture>
                                <source srcset="/img/pepe.webp, /img/pepe@2x.webp 2x" type="image/webp">
                                <source srcset="/img/pepe.png, /img/pepe@2x.png 2x"> <img alt="" height="493"
                                    src="/img/pepe.png" width="493">
                            </picture>
                        </div>

                        <div class="info__character info__character--fox">
                            <picture>
                                <source srcset="/img/foxy.webp, /img/foxy@2x.webp 2x" type="image/webp">
                                <source srcset="/img/foxy.png, /img/foxy@2x.png 2x"> <img alt="" height="411"
                                    src="/img/foxy.png" width="262">
                            </picture>
                        </div>

                        <div class="info__character info__character--spy">
                            <picture>
                                <source srcset="/img/spy.webp, /img/spy@2x.webp 2x" type="image/webp">
                                <source srcset="/img/spy.png, /img/spy@2x.png 2x"> <img alt="" height="264"
                                    src="/img/spy.png" width="162">
                            </picture>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="hero__floating-images">
            <div class="hero__cloud hero__cloud--left">
                <picture>
                    <source srcset="/img/cloud-left.webp, /img/cloud-left@2x.webp 2x" type="image/webp">
                    <source srcset="/img/cloud-left.png, /img/cloud-left@2x.png 2x"> <img alt="" height="313"
                        src="/img/cloud-left.png" width="580">
                </picture>
            </div>

            <div class="hero__cloud hero__cloud--right">
                <picture>
                    <source srcset="/img/cloud-right.webp, /img/cloud-right@2x.webp 2x" type="image/webp">
                    <source srcset="/img/cloud-right.png, /img/cloud-right@2x.png 2x"> <img alt="" height="313"
                        src="/img/cloud-right.png" width="602">
                </picture>
            </div>

            <div class="hero__stars">
                <picture>
                    <source media="(min-width: 425px)" srcset="/img/star-field.webp, /img/star-field@2x.webp 2x"
                        type="image/webp">
                    <source media="(min-width: 425px)" srcset="/img/star-field.png, /img/star-field@2x.png 2x">
                    <source media="(max-width: 425px)" srcset="/img/star-field-mob.webp, /img/star-field-mob@2x.webp 2x"
                        type="image/webp">
                    <source media="(max-width: 425px)" srcset="/img/star-field-mob.png, /img/star-field-mob@2x.png 2x">
                    <img alt="" height="1600" src="/img/star-field.png" width="1920">
                </picture>
            </div>

            <div class="hero__floating-item hero__floating-item--guitar">
                <picture>
                    <source srcset="/img/guitar.webp, /img/guitar@2x.webp 2x" type="image/webp">
                    <source srcset="/img/guitar.png, /img/guitar@2x.png 2x"> <img alt="" height="213" src="/img/guitar.png"
                        width="268">
                </picture>
            </div>

            <div class="hero__floating-item hero__floating-item--racoon">
                <picture>
                    <source srcset="/img/racoon.webp, /img/racoon@2x.webp 2x" type="image/webp">
                    <source srcset="/img/racoon.png, /img/racoon@2x.png 2x"> <img alt="" height="229" src="/img/racoon.png"
                        width="154">
                </picture>
            </div>

            <div class="hero__floating-item hero__floating-item--doll-angel">
                <picture>
                    <source srcset="/img/doll-angel.webp, /img/doll-angel@2x.webp 2x" type="image/webp">
                    <source srcset="/img/doll-angel.png, /img/doll-angel@2x.png 2x"> <img alt="" height="193"
                        src="/img/doll-angel.png" width="112">
                </picture>
            </div>

            <div class="hero__floating-item hero__floating-item--spell-book">
                <picture>
                    <source srcset="/img/spellbook.webp, /img/spellbook@2x.webp 2x" type="image/webp">
                    <source srcset="/img/spellbook.png, /img/spellbook@2x.png 2x"> <img alt="" height="328"
                        src="/img/spellbook.png" width="347">
                </picture>
            </div>

            <div class="hero__floating-item hero__floating-item--icecream">
                <picture>
                    <source srcset="/img/icecream.webp, /img/icecream@2x.webp 2x" type="image/webp">
                    <source srcset="/img/icecream.png, /img/icecream@2x.png 2x"> <img alt="" height="176"
                        src="/img/icecream.png" width="86">
                </picture>
            </div>

            <div class="hero__floating-item hero__floating-item--droplet">
                <picture>
                    <source srcset="/img/droplet.webp, /img/droplet@2x.webp 2x" type="image/webp">
                    <source srcset="/img/droplet.png, /img/droplet@2x.png 2x"> <img alt="" height="197"
                        src="/img/droplet.png" width="164">
                </picture>
            </div>
        </div>
    </section>
</template>

<style>
.video-container {
    position: relative;
    /* center content */
    justify-content: center;
    align-items: center;
    width: 100%;
}

.video-container iframe {
    /* position: absolute; */
    width: 100%;
    /* You can set a max-width if needed */
    max-width: 800px; /* Example max-width */
}
</style>

<script setup>
    import { onMounted } from 'vue'

  // Function to set the iframe's height based on its aspect ratio
  function setIframeHeight() {
    const container = document.querySelector('.video-container');
    const iframe = container.querySelector('iframe');

    const aspectRatio = 9 / 16; // 16:9 aspect ratio
    const containerWidth = container.offsetWidth;
    const iframeHeight = containerWidth * aspectRatio;

    iframe.style.height = `${iframeHeight}px`;
  }

  // Call the function when the window is resized
  window.addEventListener('resize', setIframeHeight);

//   // Call the function initially
//   setIframeHeight();

  onMounted(() => {
    setIframeHeight();
  });
</script>






