<template>
    <HeadNav/>
    <HeroComponent/>
    <VisionMap/>
    <AboutUs/>
    <KikiPosters/>
    <ShapingFuture/>
    <FooterNav/>
</template>

<script>
import HeadNav from '@/components/HeadNav.vue';
import HeroComponent from '@/components/HeroComponent.vue';
import VisionMap from '@/components/VisionMap.vue';
import AboutUs from '@/components/AboutUs.vue';
import KikiPosters from '@/components/KikiPosters.vue';
import ShapingFuture from '@/components/ShapingFuture.vue';
import FooterNav from '@/components/FooterNav.vue';

export default {
    name: 'IndexPage',
    components: {
        HeadNav,
        HeroComponent,
        VisionMap,
        AboutUs,
        KikiPosters,
        ShapingFuture,
        FooterNav,
    },
};
</script>