<template>
    <div class="header">
        <div class="container header__container">
            <div class="header__content"><a class="logo header__logo" href="#"><svg height="45" width="318">
                        <use xlink:href="img/sprite.svg#logo"></use>
                    </svg> </a>
                <div class="header__mob-curtain">
                    <nav class="header__nav">
                        <ul class="header__links">
                            <li class="header__link"><a href="#team">Team</a></li>
                            <li class="header__link"><a href="#vision">Vision</a></li>
                            <li class="header__link"><a href="#mission">Mission</a></li>
                            <!-- <li class="header__link"><a href="http://merch.kikiverse.gg/">Merch</a></li> -->
                        </ul>
                    </nav>
                </div>

                <ul class="header__socials">
                    <li class="header__social header__social--twitter"><a href="https://twitter.com/KikiVerseNFT"><svg
                                height="45" width="55">
                                <use xlink:href="img/sprite.svg#twitter"></use>
                            </svg> </a></li>
                    <li class="header__social header__social--discord"><a href="https://discord.gg/z8Hz8xz5"><svg
                                height="45" width="55">
                                <use xlink:href="img/sprite.svg#discord"></use>
                            </svg> </a></li>
                </ul>
                <button class="hamburger header__hamburger"></button>

                <div class="hamburger__lines"></div>
                <button class="hamburger header__hamburger"></button>
            </div>
        </div>
    </div>
</template>