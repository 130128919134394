import { createRouter, createWebHistory } from 'vue-router';
import IndexPage from '@/pages/IndexPage.vue'; // Import your pages as needed
import NotFound from '@/pages/NotFound.vue';

const routes = [
  {
    path: '/',
    component: IndexPage, // Use your page components here
  },
  // Add a wildcard route for 404
  {
    path: '/:catchAll(.*)',
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
